import React, { useEffect, useState } from 'react';
import {
    Box, Typography, Button, TextField, Snackbar, Alert as MuiAlert,
    Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody,
    TableCell, TableHead, TableRow, TableContainer, Paper
} from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ManageTariffs = ({ api }) => {
    const [tariffs, setTariffs] = useState([]);  // List of tariffs
    const [newTariffPrice, setNewTariffPrice] = useState('');  // New tariff price
    const [dialogOpen, setDialogOpen] = useState(false);  // Dialog state for setting new tariff
    const [snackbarOpen, setSnackbarOpen] = useState(false);  // Snackbar for notifications
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    // Fetch tariffs (active and inactive)
    const fetchTariffs = async () => {
        const token = localStorage.getItem('access_token');
        try {
            const response = await fetch(`${api}/tariffs`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) throw new Error('Failed to fetch tariffs');
            const data = await response.json();
            setTariffs(data);
        } catch (error) {
            setSnackbarMessage('Error fetching tariffs.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    useEffect(() => {
        fetchTariffs();
    }, [api]);

    // Handle deactivate tariff
    const deactivateTariff = async (tariffId) => {
        const token = localStorage.getItem('access_token');
        try {
            const response = await fetch(`${api}/tariffs/${tariffId}/deactivate`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) throw new Error('Failed to deactivate tariff');
            fetchTariffs();  // Refresh tariffs
            setSnackbarMessage('Tariff deactivated successfully.');
            setSnackbarSeverity('success');
        } catch (error) {
            setSnackbarMessage('Error deactivating tariff.');
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
        }
    };

    // Handle creating a new tariff
    const createTariff = async () => {
        const token = localStorage.getItem('access_token');
        const newTariff = {
            price: parseInt(newTariffPrice, 10),
            imposed_date: new Date().toISOString(),  // Current date
            is_active: true
        };

        try {
            const response = await fetch(`${api}/tariffs`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(newTariff)
            });
            if (!response.ok) throw new Error('Failed to create tariff');
            fetchTariffs();  // Refresh tariffs
            setSnackbarMessage('New tariff created and activated successfully.');
            setSnackbarSeverity('success');
        } catch (error) {
            setSnackbarMessage('Error creating new tariff.');
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
            setDialogOpen(false);  // Close dialog
        }
    };

    // Snackbar close handler
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbarOpen(false);
    };

    // Open dialog to create a new tariff
    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    // Close dialog
    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    return (
        <Box>
            <Typography variant="h5" gutterBottom>
                Manage Tariffs
            </Typography>

            <Button variant="contained" color="primary" onClick={handleOpenDialog}>
                Set New Tariff
            </Button>

            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Price (RWF)</TableCell>
                            <TableCell>Imposed Date</TableCell>
                            <TableCell>End Date</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tariffs.map((tariff) => (
                            <TableRow key={tariff.id}>
                                <TableCell>{tariff.price}</TableCell>
                                <TableCell>{new Date(tariff.imposed_date).toLocaleDateString()}</TableCell>
                                <TableCell>
                                    {tariff.end_date ? new Date(tariff.end_date).toLocaleDateString() : 'N/A'}
                                </TableCell>
                                <TableCell>
                                    {tariff.is_active ? (
                                        <Typography color="green">Active</Typography>
                                    ) : (
                                        <Typography color="red">Inactive</Typography>
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                    {tariff.is_active && (
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={() => deactivateTariff(tariff.id)}
                                        >
                                            Deactivate
                                        </Button>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Dialog to set a new tariff */}
            <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>Set New Tariff</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Tariff Price (RWF)"
                        fullWidth
                        variant="outlined"
                        value={newTariffPrice}
                        onChange={(e) => setNewTariffPrice(e.target.value)}
                        sx={{ marginTop: 2 }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={createTariff} color="secondary">
                        Set Tariff
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ManageTariffs;
