import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Typography,
    Snackbar,
    Alert as MuiAlert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RequireAuth from '../components/RequireAuth';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ManageOrganizationUsers = ({ api }) => {
    const [users, setUsers] = useState([]);
    const [subscriptions, setSubscriptions] = useState({});
    const [loading, setLoading] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [userIdToDelete, setUserIdToDelete] = useState(null);
    const navigate = useNavigate();

    // Function to fetch users
    const fetchUsers = async () => {
        const token = localStorage.getItem('access_token');
        try {
            const response = await fetch(`${api}/organization_users`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            setUsers(data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    // Function to fetch subscription statuses for the organization users
    const fetchSubscriptions = async () => {
        const token = localStorage.getItem('access_token');
        try {
            const response = await fetch(`${api}/subscriptions/organization-users`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            const subscriptionsByUserId = data.reduce((acc, sub) => {
                acc[sub.user_id] = sub;
                return acc;
            }, {});
            setSubscriptions(subscriptionsByUserId);
        } catch (error) {
            console.error('Error fetching subscription statuses:', error);
        }
    };

    // Fetch users and their subscriptions
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await fetchUsers(); // Fetch users first
            await fetchSubscriptions(); // Then fetch their subscriptions
            setLoading(false);
        };
        fetchData();
    }, [api]);

    const handleDelete = async (userId) => {
        const token = localStorage.getItem('access_token');
        try {
            const response = await fetch(`${api}/organization_user/${userId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                setSnackbarMessage('User deleted successfully.');
                setSnackbarSeverity('success');
                fetchUsers(); // Refetch users after deletion
            } else {
                setSnackbarMessage('Failed to delete user.');
                setSnackbarSeverity('error');
            }
        } catch (error) {
            setSnackbarMessage('Failed to delete user.');
            setSnackbarSeverity('error');
        }
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleCreateNewUser = () => {
        navigate('/register/organization_user');
    };

    const handleDialogOpen = (userId) => {
        setUserIdToDelete(userId);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleDialogConfirmDelete = () => {
        handleDelete(userIdToDelete);
        handleDialogClose();
    };

    const handleActivateSubscription = (userId) => {
        // Dummy handler for activating a subscription
        setSnackbarMessage(`Subscription for user ${userId} will be activated (dummy action)`);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
    };

    if (loading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        );
    }

    return (
        <RequireAuth>
            <Container>
                <Typography variant="h4" gutterBottom>
                    Manage Organization Users
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreateNewUser}
                    sx={{ mb: 2 }}
                >
                    Create New User
                </Button>
                {users.length === 0 ? (
                    <Typography variant="h6" align="center" sx={{ mt: 4 }}>
                        You have not created any users.
                    </Typography>
                ) : (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>User Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Phone</TableCell>
                                    <TableCell>Subscription Status</TableCell>
                                    <TableCell>Start Date</TableCell>
                                    <TableCell>End Date</TableCell>
                                    <TableCell align="center">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map((user) => {
                                    const subscription = subscriptions[user.id];  // Get user's subscription
                                    return (
                                        <TableRow key={user.id}>
                                            <TableCell>{user.user_name}</TableCell>
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell>{user.phone || 'N/A'}</TableCell>
                                            <TableCell>
                                                {subscription?.is_active ? (
                                                    <span style={{ color: 'green' }}>Active</span>
                                                ) : (
                                                    <span style={{ color: 'red' }}>Inactive</span>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {subscription?.start_date
                                                    ? new Date(subscription.start_date).toLocaleDateString()
                                                    : 'N/A'}
                                            </TableCell>
                                            <TableCell>
                                                {subscription?.end_date
                                                    ? new Date(subscription.end_date).toLocaleDateString()
                                                    : 'N/A'}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleActivateSubscription(user.id)}
                                                    sx={{ mr: 1 }}
                                                    disabled={subscription?.is_active}
                                                >
                                                    Activate Subscription
                                                </Button>
                                                <IconButton
                                                    edge="end"
                                                    aria-label="delete"
                                                    onClick={() => handleDialogOpen(user.id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                )}
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
                <Dialog open={dialogOpen} onClose={handleDialogClose}>
                    <DialogTitle>Confirm Delete</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this user?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleDialogConfirmDelete} color="secondary">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </RequireAuth>
    );
};

export default ManageOrganizationUsers;
