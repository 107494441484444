import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./pages/layout.js"
import Home from "./pages/Home.js";
import AdminForm from "./pages/Admin.js"
import RegisterIndividual from './pages/RegisterIndividual.js';
import RegisterOrganization from './pages/RegisterOrganization.js';
import RegisterOrganizationUser from './pages/RegisterOrganizationUser.js';
import Login from './pages/login.js';
import AccountSettings from './pages/AccountSettings.js';
import ManageOrganizationUsers from './pages/ManageOrganizationUsers';
import Favorites from './pages/Favorites';
import { Typography } from "@mui/material";
import AddChapter from './pages/addchapter';
import UpdateDeleteChapter from './pages/updateChapter';
import AddReference from './pages/AddReference.js'
import UpdateReference from './pages/UpdateReference.js'
import ContactPage from './pages/ContactPage';
import ManageQuotes from './pages/ManageQuotes.js'
import ManageTariffs from "./pages/ManageTariffs.js";

function App() {
  const api = process.env.REACT_APP_API_URL;
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home api={api} />} />
          <Route path="/museke" element={<AdminForm api={api} />} />
          <Route path="/museke/addchapter" element={<AddChapter api={api} />} />
          <Route path="/museke/addreference" element={<AddReference api={api} />} />
          <Route path="/museke/updatereference" element={<UpdateReference api={api} />} />
          <Route path="/museke/updatechapters" element={<UpdateDeleteChapter api={api} />} />
          <Route path="/museke/managequotes" element={<ManageQuotes api={api} />} />
          <Route path="/museke/managetariffs" element={<ManageTariffs api={api} />} />
          <Route path="/register/individual" element={<RegisterIndividual api={api} />} />
          <Route path="/register/organization" element={<RegisterOrganization api={api} />} />
          <Route path="/register/organization_user" element={<RegisterOrganizationUser api={api} />} />
          <Route path="/favorites" element={<Favorites api={api} />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/login" element={<Login api={api} />} />
          <Route path="/manage_organization_users" element={<ManageOrganizationUsers api={api} />} />
          <Route path="/account_settings" element={<AccountSettings api={api} />} />
          <Route
            path="*"
            element={<Typography>404</Typography>}
          />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
