import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  IconButton,
  Snackbar,
  Alert as MuiAlert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Select,
  MenuItem,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { format } from 'date-fns';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  margin: '10px 0',
  boxShadow: 'none',
  border: '1px solid #ddd',
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#f5f5f5',
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#444' : '#fafafa',
  padding: '20px',
}));

const HighlightedText = ({ text, highlight }) => {
  if (!text || !highlight.trim()) {
    return <span>{text}</span>;
  }

  const regex = new RegExp(`(${highlight})`, 'gi');
  const parts = text.split(regex);

  return (
    <span>
      {parts.map((part, index) =>
        regex.test(part) ? (
          <span key={index} style={{ backgroundColor: 'yellow' }}>
            {part}
          </span>
        ) : (
          <span key={index}>{part}</span>
        )
      )}
    </span>
  );
};

const ChaptersList = ({ api }) => {
  const { searchQuery, selectedLanguage, selectedStatus, selectedYear } = useOutletContext();
  const [chapters, setChapters] = useState([]);
  const [favorites, setFavorites] = useState({});
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [references, setReferences] = useState({});
  const [subscriptionStatus, setsubscriptionStatus] = useState(false);
  const [page, setPage] = useState(1);
  const [chaptersPerPage, setChaptersPerPage] = useState(10); // Default to 10 chapters per page
  const theme = useTheme();

  useEffect(() => {
    const fetchChapters = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${api}/chapters/all`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.status === 403) {
          setSnackbarMessage('Your subscription has ended. Please renew your subscription to access the chapters.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setChapters([]);
        }
        else if (response.status === 500) {
          setSnackbarMessage('Sorry, the server encountered an error.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setChapters([]);
        }
        else {
          const data = await response.json();
          setsubscriptionStatus(true)
          setChapters(data);
        }
      } catch (error) {
        console.error('Error fetching chapters:', error);
        setChapters([]);
      } finally {
        setLoading(false);
      }
    };

    const fetchFavorites = async () => {
      try {
        const token = localStorage.getItem('access_token');
        if (!token) {
          setSnackbarMessage('You must be logged in to view favorites.');
          setSnackbarSeverity('warning');
          setSnackbarOpen(true);
          return;
        }
        const response = await fetch(`${api}/favorites`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.status === 403) {
          setSnackbarMessage('Your subscription has ended. Please renew your subscription to view your favorites.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setFavorites({});
        } else {
          const data = await response.json();
          setFavorites(data.reduce((acc, fav) => {
            acc[`${fav.item_type}-${fav.item_id}`] = true;
            return acc;
          }, {}));
        }
      } catch (error) {
        console.error('Error fetching favorites:', error);
      }
    };

    const fetchReferences = async () => {
      try {
        const response = await fetch(`${api}/references`);
        const data = await response.json();
        setReferences(data.reduce((acc, ref) => {
          acc[ref.id] = ref;
          return acc;
        }, {}));
      } catch (error) {
        console.error('Error fetching references:', error);
      }
    };

    fetchChapters();
    fetchFavorites();
    fetchReferences();
  }, [api]);

  const handleFavorite = async (itemId, itemType) => {
    const key = `${itemType}-${itemId}`;
    const isFavorited = favorites[key];
    const token = localStorage.getItem('access_token');

    if (!token) {
      setSnackbarMessage('You must be logged in to favorite items.');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    if (isFavorited) {
      setDialogData({ itemId, itemType });
      setDialogOpen(true);
    } else {
      try {
        const response = await fetch(`${api}/favorites/add`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ item_id: itemId, item_type: itemType })
        });

        if (response.status === 401) {
          setSnackbarMessage('Unauthorized. Please log in again.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          return;
        }

        if (response.ok) {
          setFavorites(prev => ({ ...prev, [key]: !isFavorited }));
          setSnackbarMessage('Added to favorites!');
          setSnackbarSeverity('success');
        } else {
          setSnackbarMessage('Failed to update favorites.');
          setSnackbarSeverity('error');
        }
      } catch (error) {
        setSnackbarMessage('Error updating favorites.');
        setSnackbarSeverity('error');
      } finally {
        setSnackbarOpen(true);
      }
    }
  };

  const handleRemoveFavorite = async () => {
    const { itemId, itemType } = dialogData;
    const key = `${itemType}-${itemId}`;
    const token = localStorage.getItem('access_token');

    try {
      const response = await fetch(`${api}/favorites/remove`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ item_id: itemId, item_type: itemType })
      });

      if (response.status === 401) {
        setSnackbarMessage('Unauthorized. Please log in again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      if (response.ok) {
        setFavorites(prev => {
          const updatedFavorites = { ...prev };
          delete updatedFavorites[key];
          return updatedFavorites;
        });
        setSnackbarMessage('Removed from favorites!');
        setSnackbarSeverity('success');
      } else {
        setSnackbarMessage('Failed to update favorites.');
        setSnackbarSeverity('error');
      }
    } catch (error) {
      setSnackbarMessage('Error updating favorites.');
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
      setDialogOpen(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const naturalCompare = (a, b) => {
    const extractNumber = (text) => {
      const match = text.match(/(\d+)/);
      return match ? parseInt(match[0], 10) : 0;
    };

    const aNumber = extractNumber(a);
    const bNumber = extractNumber(b);

    if (aNumber === bNumber) {
      return a.localeCompare(b);
    }

    return aNumber - bNumber;
  };

  const handleChaptersPerPageChange = (event) => {
    setChaptersPerPage(event.target.value);
    setPage(1);  // Reset to first page when changing items per page
  };

  const startIndex = (page - 1) * chaptersPerPage;
  const endIndex = startIndex + chaptersPerPage;

  const filteredChapters = chapters.length === 0 ? [] : chapters
    .map(chapter => {
      const filteredSections = chapter.sections_lst
        .map(section => {
          const filteredArticles = section.articles_lst
            .filter(article => {
              const articleStatusMatches = selectedStatus ? article.status === selectedStatus : true;
              const articleYearMatches = selectedYear ? new Date(article.effective_date).getFullYear() === parseInt(selectedYear, 10) : true;
              const articleMatchesQuery = article.translations.some(translation => {
                return translation.language_code === selectedLanguage && (
                  translation.text.toLowerCase().includes(searchQuery.toLowerCase()) ||
                  translation.desc.toLowerCase().includes(searchQuery.toLowerCase())
                );
              });

              return articleStatusMatches && articleYearMatches && articleMatchesQuery;
            })
            .sort((a, b) => {
              const aText = a.translations.find(t => t.language_code === selectedLanguage)?.text || a.title;
              const bText = b.translations.find(t => t.language_code === selectedLanguage)?.text || b.title;
              return naturalCompare(aText, bText);
            });

          if (filteredArticles.length > 0) {
            return {
              ...section,
              articles_lst: filteredArticles,
            };
          }
          return null;
        })
        .filter(section => section !== null)
        .sort((a, b) => {
          const aText = a.translations.find(t => t.language_code === selectedLanguage)?.text || a.name;
          const bText = b.translations.find(t => t.language_code === selectedLanguage)?.text || b.name;
          return naturalCompare(aText, bText);
        });

      if (filteredSections.length > 0) {
        return {
          ...chapter,
          sections_lst: filteredSections,
        };
      }
      return null;
    })
    .filter(chapter => chapter !== null)
    .sort((a, b) => {
      const aText = a.translations.find(t => t.language_code === selectedLanguage)?.text || a.name;
      const bText = b.translations.find(t => t.language_code === selectedLanguage)?.text || b.name;
      return naturalCompare(aText, bText);
    });

  if (loading) {
    return (
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container>
      <Typography sx={{ textAlign: "center" }} variant="h6">Rwandan Labour Laws</Typography>

      {subscriptionStatus === false ? (
        <Typography variant="h6" align="center" color="error" sx={{ marginTop: 2 }}>
          Your subscription has ended. Please renew your subscription to access the chapters.
        </Typography>
      ) : (
        filteredChapters.slice(startIndex, endIndex).map((chapter, chapterIndex) => (
          <StyledAccordion key={chapterIndex}>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">
                📖 <HighlightedText text={chapter.translations.find(t => t.language_code === selectedLanguage)?.text || chapter.name} highlight={searchQuery} />
                <IconButton onClick={(e) => { e.stopPropagation(); handleFavorite(chapter.id, 'chapter'); }}>
                  {favorites[`chapter-${chapter.id}`] ? <StarIcon /> : <StarBorderIcon />}
                </IconButton>
              </Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              {chapter.sections_lst.map((section, sectionIndex) => (
                <StyledAccordion key={sectionIndex}>
                  <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">
                      <HighlightedText text={section.translations.find(t => t.language_code === selectedLanguage)?.text || section.name} highlight={searchQuery} />
                      <IconButton onClick={(e) => { e.stopPropagation(); handleFavorite(section.id, 'section'); }}>
                        {favorites[`section-${section.id}`] ? <StarIcon /> : <StarBorderIcon />}
                      </IconButton>
                    </Typography>
                  </StyledAccordionSummary>
                  <StyledAccordionDetails>
                    <Typography variant="subtitle2" gutterBottom>Articles</Typography>
                    {section.articles_lst.map((article, articleIndex) => (
                      <StyledAccordion key={articleIndex}>
                        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography variant="body1">
                            <HighlightedText text={article.translations.find(t => t.language_code === selectedLanguage)?.text || article.title} highlight={searchQuery} />
                            <IconButton onClick={(e) => { e.stopPropagation(); handleFavorite(article.id, 'article'); }}>
                              {favorites[`article-${article.id}`] ? <StarIcon /> : <StarBorderIcon />}
                            </IconButton>
                          </Typography>
                        </StyledAccordionSummary>
                        <StyledAccordionDetails>
                          <Typography>
                            <HighlightedText text={article.translations.find(t => t.language_code === selectedLanguage)?.desc || article.text} highlight={searchQuery} />
                          </Typography>
                          <Typography variant='h4'>Effective Date: {format(new Date(article.effective_date), 'MMMM dd, yyyy')}</Typography>
                          <Typography variant='h4'>Status: {article.status}</Typography>
                          {article.reference_id && references[article.reference_id] && (
                            <Typography variant='h4'>
                              Reference: <a href={`${api}/reference/${article.reference_id}/download`} target="_blank" rel="noopener noreferrer" style={{ color: 'lightblue' }}>{references[article.reference_id].description}</a>
                            </Typography>
                          )}
                        </StyledAccordionDetails>
                      </StyledAccordion>
                    ))}
                  </StyledAccordionDetails>
                </StyledAccordion>
              ))}
            </StyledAccordionDetails>
          </StyledAccordion>
        ))
      )}

      {/* Pagination Selector positioned at bottom left */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 3 }}>
        <Select
          value={chaptersPerPage}
          onChange={handleChaptersPerPageChange}
          displayEmpty
          sx={{ marginBottom: 2 }}
        >
          <MenuItem value={10}>Show 10</MenuItem>
          <MenuItem value={30}>Show 30</MenuItem>
          <MenuItem value={50}>Show 50</MenuItem>
          <MenuItem value={100}>Show 100</MenuItem>
        </Select>
      </Box>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Remove Favorite"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this item from your favorites?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            sx={{ color: theme.palette.primary.main }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleRemoveFavorite}
            autoFocus
            sx={{ color: theme.palette.primary.main }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ChaptersList;
