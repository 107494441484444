import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
    Container, TextField, Button, Typography, Snackbar, Select, MenuItem,
    FormControl, InputLabel, Alert as MuiAlert, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { AuthContext } from '../contexts/AuthContext';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RegisterOrganization = ({ api }) => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { login, user_type, user_name } = useContext(AuthContext);
    const [snackbarState, setSnackbarState] = useState({ open: false, message: '', severity: 'success' });
    const [position, setPosition] = useState('');
    const [customPosition, setCustomPosition] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);  // Control dialog state
    const [userData, setUserData] = useState(null);  // Hold user data for login after dialog
    const navigate = useNavigate();

    const positionOptions = [
        "Intern/Trainee", "Entry-Level", "Junior", "Mid-Level", "Senior",
        "Manager", "Director", "Vice President",
        "C-Level Executive (CEO, CFO, COO, etc.)",
        "Business Owner/Entrepreneur", "Other"
    ];

    const onSubmit = (data) => {
        const { confirmPassword, position, ...finalData } = data;
        const finalPosition = position === 'Other' ? customPosition : position;
        const finalUserData = { ...finalData, position: finalPosition };

        // Register organization
        fetch(`${api}/register/organization`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(finalUserData)
        })
            .then(response => response.ok ? response.json() : response.json().then(err => { throw err }))
            .then(() => {
                setSnackbarState({ open: true, message: 'Organization registered successfully!', severity: 'success' });
                setUserData(finalUserData);  // Store data for login after trial dialog
                setDialogOpen(true);  // Open free trial dialog
            })
            .catch(error => {
                setSnackbarState({ open: true, message: error.detail || 'Failed to register organization.', severity: 'error' });
            });
    };

    // Handle login after closing the dialog
    const handleCloseDialog = () => {
        setDialogOpen(false);
        if (userData) {
            loginUser(userData);
        }
    };

    const loginUser = (userData) => {
        const formData = new URLSearchParams();
        formData.append('username', userData.email);
        formData.append('password', userData.password);

        fetch(`${api}/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: formData
        })
            .then(response => response.ok ? response.json() : response.json().then(err => { throw err }))
            .then(data => {
                login(data.access_token);
                user_type(data.user_type);
                user_name(data.user_name);
                setSnackbarState({ open: true, message: 'Login succeeded!', severity: 'success' });
                navigate('/');
            })
            .catch(() => {
                setSnackbarState({ open: true, message: 'Registration succeeded, but login failed.', severity: 'error' });
            });
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbarState(prevState => ({ ...prevState, open: false }));
    };

    const password = watch("password");

    return (
        <Container sx={{ paddingTop: "10px" }}>
            <Typography variant="h4" gutterBottom>
                Register Organization
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    label="User name"
                    {...register('user_name', { required: true })}
                    fullWidth
                    margin="normal"
                    error={!!errors.user_name}
                    helperText={errors.user_name ? 'User name is required' : ''}
                />
                <TextField
                    label="Email"
                    {...register('email', { required: true })}
                    fullWidth
                    margin="normal"
                    error={!!errors.email}
                    helperText={errors.email ? 'Email is required' : ''}
                />
                <TextField
                    label="Phone"
                    {...register('phone', { required: true })}
                    fullWidth
                    margin="normal"
                    error={!!errors.phone}
                    helperText={errors.phone ? 'Phone is required' : ''}
                />
                <FormControl fullWidth margin="normal" error={!!errors.position}>
                    <InputLabel>Position</InputLabel>
                    <Select
                        label="Position"
                        value={position}
                        {...register('position', { required: true })}
                        onChange={(e) => setPosition(e.target.value)}
                    >
                        {positionOptions.map((option) => (
                            <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {position === 'Other' && (
                    <TextField
                        label="Custom Position"
                        value={customPosition}
                        onChange={(e) => setCustomPosition(e.target.value)}
                        fullWidth
                        margin="normal"
                        error={!!errors.customPosition}
                        helperText={errors.customPosition ? 'Custom Position is required' : ''}
                    />
                )}
                <TextField
                    label="Organization Name"
                    {...register('organization', { required: true })}
                    fullWidth
                    margin="normal"
                    error={!!errors.organization}
                    helperText={errors.organization ? 'Organization Name is required' : ''}
                />
                <TextField
                    label="Employees Size"
                    type="number"
                    {...register('employees_size', { required: true })}
                    fullWidth
                    margin="normal"
                    error={!!errors.employees_size}
                    helperText={errors.employees_size ? 'Employees size is required' : ''}
                />
                <TextField
                    label="Password"
                    type="password"
                    {...register('password', { required: true, minLength: 8 })}
                    fullWidth
                    margin="normal"
                    error={!!errors.password}
                    helperText={errors.password ? 'Password must be at least 8 characters long' : ''}
                />
                <TextField
                    label="Confirm Password"
                    type="password"
                    {...register('confirmPassword', {
                        required: true,
                        validate: value => value === password || "Passwords do not match"
                    })}
                    fullWidth
                    margin="normal"
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword ? errors.confirmPassword.message : ''}
                />
                <Button type="submit" variant="contained" color="primary">
                    Register
                </Button>
            </form>

            <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>30-Day Free Trial</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Your organization has been given a 30-day free trial
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Start Free Trial
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={snackbarState.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarState.severity} sx={{ width: '100%' }}>
                    {snackbarState.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default RegisterOrganization;
